import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { visibility } from 'shared/enhancers';

import TableToolbar from './TableToolbar';
import DataTablePagination from './Pagination';
import TableActions from './TableActions';
import {
  changeSort,
  changePage,
  changeRowsPerPage,
  changeFilters,
  changeSearch,
} from './actions';
import filterActions from './filterActions';

import './DataTable.scss';

const handleFetchData = (
  onFetchData = () => {},
  page = 0,
  pageSize = 20,
  sorted,
  filters,
  searchText
) => {
  const skipCount = page * pageSize;
  const maxResultCount = pageSize;
  const sorting =
    sorted && sorted.length
      ? `${sorted[0].id}${sorted[0].desc ? ' desc' : ''}`
      : '';

  onFetchData({
    skipCount,
    maxResultCount,
    sorting,
    searchText,
    ...filters,
  });
};

const selectTableMetaData = (state, { name }) => state.dataTable[name] || {};

const enhance = compose(
  withProps(({ visible }) => ({
    visible: ((visible === undefined || visible === null) && true) || visible,
  })),
  visibility,
  connect(
    (state, props) => {
      const metaData = selectTableMetaData(state, props);

      return {
        metaData,
        formatExtraData: {
          ...props.formatExtraData,
        },
      };
    },
    (dispatch, props) => ({
      storePage: page => {
        dispatch(
          changePage({
            name: props.name,
            page,
          })
        );
        if (typeof props.onPageChange === 'function') props.onPageChange();
      },
      storePageSize: pageSize => {
        dispatch(
          changeRowsPerPage({
            name: props.name,
            pageSize,
          })
        );
        if (typeof props.onPageSizeChange === 'function')
          props.onPageSizeChange();
      },
      storeSorted: sorted => {
        dispatch(
          changeSort({
            name: props.name,
            sorted,
          })
        );
        if (typeof props.onSortedChange === 'function') props.onSortedChange();
      },
      storeFilters: filters => {
        dispatch(
          changeFilters({
            name: props.name,
            filters,
          })
        );
      },
      storeSearch: searchText => {
        dispatch(
          changeSearch({
            name: props.name,
            searchText,
          })
        );
      },
    })
  ),
  withState('searchTimeout', 'setSearchTimeout'),
  withState('filters', 'setFilters', {}),
  withHandlers({
    isSelected: props => id => props.selected.indexOf(id) > -1,
    onSearchChanged: props => searchText => {
      if (props.onFetchData) {
        const {
          metaData: { page, pageSize, sorted, filters },
        } = props;

        clearTimeout(props.searchTimeout);

        const searchTimeout = setTimeout(() => {
          handleFetchData(
            props.onFetchData,
            page,
            pageSize,
            sorted,
            filters,
            searchText
          );
        }, 500);
        props.setSearchTimeout(searchTimeout);
      }
      props.storeSearch(searchText);
      props.storePage(0);
    },
    onPageChange: props => page => {
      props.storePage(page);
      const meta = props.metaData;
      handleFetchData(
        props.onFetchData,
        page,
        meta.pageSize,
        meta.sorted,
        meta.filters,
        props.searchText
      );
    },
    onPageSizeChange: props => pageSize => {
      props.storePageSize(pageSize);
      const meta = props.metaData;
      props.storePage(0);
      handleFetchData(
        props.onFetchData,
        0,
        pageSize,
        meta.sorted,
        meta.filters,
        props.searchText
      );
    },
    onSortedChange: props => sorted => {
      props.storeSorted(sorted);
      const meta = props.metaData;
      props.storePage(0);
      handleFetchData(
        props.onFetchData,
        0,
        meta.pageSize,
        sorted,
        meta.filters,
        props.searchText
      );
    },
    updateFiltersHandler: props => filter => {
      const meta = props.metaData;
      const newFilters = { ...meta.filters };
      newFilters[Object.keys(filter)[0]] = filter[Object.keys(filter)[0]];
      props.storeFilters(newFilters);
      props.storePage(0);
      handleFetchData(
        props.onFetchData,
        0,
        meta.pageSize,
        meta.sorted,
        newFilters
      );
    },
  })
);

function getCurrencyValue(currencySetting, value) {
  if (currencySetting !== undefined) {
    return new Intl.NumberFormat(
      currencySetting.locale !== undefined ? currencySetting.locale : 'en-US',
      {
        style: 'currency',
        currency:
          currencySetting.currencyCode !== undefined
            ? currencySetting.currencyCode
            : 'USD',
        minimumFractionDigits:
          currencySetting.minimumFractionDigits !== undefined
            ? currencySetting.minimumFractionDigits
            : 2,
        maximumFractionDigits:
          currencySetting.maximumFractionDigits !== undefined
            ? currencySetting.maximumFractionDigits
            : 2,
      }
    ).format(value !== undefined ? value : 0);
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value !== undefined ? value : 0);
}

function transformCols(cols, actions) {
  const processedCols = cols.map(c => {
    const col = {
      ...c,
      Header: (
        <span className="column-name">
          {c.title} <span className="sort-icon lnr lnr-arrow-up" />
          <span className="sort-icon lnr lnr-arrow-down" />
        </span>
      ),
      accessor: c.field,
    };

    if (col.render) {
      col.Cell = ({ original, ...rest }) => col.render(original, rest);
    }

    if (c.type && !col.Cell) {
      if (c.type === 'date' || c.type === 'time' || c.type === 'datetime') {
        col.Cell = ({ value }) => {
          if (value instanceof Date) {
            return value.toLocaleDateString();
          }
          return value;
        };
      }

      if (c.type === 'currency') {
        col.Cell = ({ value }) => getCurrencyValue(c.currencySetting, value);
      }

      if (c.type === 'boolean') {
        col.Cell = ({ value }) => (value || false).toString();
      }
    }

    if (!col.Cell) {
      // To support empty values
      col.Cell = ({ value }) => value || '';
    }

    if (col.customSort) {
      col.sortMethod = col.customSort;
    }

    return col;
  });

  const processActionsCols = rowActions => {
    if (!rowActions.length) return [];

    return [
      {
        sortable: false,
        maxWidth: 148,
        className: 'table-actions',
        // eslint-disable-next-line react/prop-types
        Cell: ({ original }) => (
          <TableActions actions={rowActions} data={original} />
        ),
        Header: <span className="column-name">Actions</span>,
        headerClassName: 'table-actions',
      },
    ];
  };

  const additionalCols = processActionsCols(
    actions.filter(
      filterActions({
        selection: false,
      })
    )
  );

  return [...processedCols, ...additionalCols];
}

function transformDefaultSorted(defaultSorted = [], cols) {
  return [
    ...defaultSorted,
    ...cols
      .filter(x => x.defaultSort)
      .map(x => ({
        id: x.id || x.accessor,
        desc: x.defaultSort !== 'asc',
      })),
  ];
}

function processSearch(searchText = '', cols, onFetchData) {
  if (onFetchData) return data => data;
  return data => {
    if (!searchText.length) return data;

    const dataCols = cols.map(x => x.accessor);

    const results = data.filter(x => {
      const r = dataCols.map(c =>
        JSON.stringify(x[c] || '')
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );

      return r.filter(c => c).length > 0;
    });

    return results;
  };
}

// TODO: materal-table currently makes changes to the data that makes it back to
// redux.  This stops that.  Temporary fix until I get time to fix correct
const prepareData = data =>
  data.map(x => ({
    ...x,
  }));

const getNumberOfPages = (totalItems, pageSize) =>
  Math.ceil(totalItems / pageSize) || 1;

const DataTable = ({
  options,
  actions,
  data,
  filterConfig,
  metaData,
  columns,
  defaultSorted,
  onSearchChanged,
  updateFiltersHandler,
  totalCount,
  onFetchData,
  inMemoryFiltering,
  ...props
}) => {
  const finalOptions = {
    emptyRowsWhenPaging: false,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
    ...options,
    ...metaData,
  };

  let filteredData = [...data];

  if (inMemoryFiltering)
    filteredData = inMemoryFiltering(metaData.filters, filteredData);

  const resultCount = totalCount || filteredData.length;

  const cols = transformCols(columns, actions);

  const toolbarVisible = () => {
    const { hideToolbar } = props;
    return !hideToolbar;
  };

  return (
    <ReactTable
      {...props}
      {...finalOptions}
      className="-striped"
      columns={cols}
      data={prepareData(filteredData)}
      defaultSorted={transformDefaultSorted(defaultSorted, cols)}
      minRows={0}
      PaginationComponent={DataTablePagination}
      resizable={false}
      resolveData={processSearch(metaData.searchText, cols, onFetchData)}
      pages={getNumberOfPages(resultCount, finalOptions.pageSize)}
      manual={!!onFetchData}
    >
      {({ filters, ...state }, makeTable) => (
        <React.Fragment>
          {toolbarVisible() && (
            <TableToolbar
              onSearchChanged={onSearchChanged}
              searchText={metaData.searchText}
              actions={actions}
              state={state}
              options={options}
              filters={filters}
              filterConfig={filterConfig}
              updateFilters={updateFiltersHandler}
              totalCount={resultCount}
            />
          )}
          {makeTable()}
        </React.Fragment>
      )}
    </ReactTable>
  );
};

DataTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  options: PropTypes.shape(),
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.func])
  ),
  data: PropTypes.arrayOf(PropTypes.shape()),
  onFetchData: PropTypes.func,
  updateFiltersHandler: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  inMemoryFiltering: PropTypes.func,
};

DataTable.defaultProps = {
  title: '',
  options: {},
  actions: [],
  data: [],
  onFetchData: undefined,
  totalCount: 0,
  inMemoryFiltering: undefined,
};

export default enhance(DataTable);
